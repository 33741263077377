
import { useEdiItemCodeMappingMaintenanceVM } from "presentation/hook/EdiItemCodeMapping/useEdiItemCodeMappingMaintenanceVM";
import { useEdiItemCodeMappingMaintenanceTracked } from "presentation/store/EdiItemCodeMapping/EdiItemCodeMappingMaintenanceProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import EdiItemCodeMappingMaintenanceFormPanel from "./EdiItemCodeMappingMaintenanceFormPanel";
import EdiItemCodeMappingTablePanel from "./EdiItemCodeMappingTablePanel";

const EdiItemCodeMappingMasterView: React.FC = () => {
    const [ediItemCodeMappingState] = useEdiItemCodeMappingMaintenanceTracked();
    const [isLoading, setIsLoading] = useState(false);
    const ediItemCodeMappingVM = useEdiItemCodeMappingMaintenanceVM();


    useEffect(() => {
        const initialScreen = async () => {
            setIsLoading(true);
            try {
                const results = await Promise.allSettled([
                    ediItemCodeMappingVM.loadDropdownOption(),
                    ediItemCodeMappingVM.onSearch()
                ]);
                results?.forEach((result, index) => {
                    if (index === 0 && result.status === 'fulfilled') {

                    }
                })
            } catch (error) {
                setIsLoading(false);
            }
        }
        initialScreen().then(() => {            
            setIsLoading(false)
        }).catch(error => {
            setIsLoading(false)
        });

    }, [ediItemCodeMappingVM])



    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    return <>
        <div className="main-comp-wrapper im-hide-side-form-draggable">
            {(!ediItemCodeMappingState.isShowDtl) && <EdiItemCodeMappingTablePanel />}
            {
                ((ediItemCodeMappingState.isShowDtl) &&
                    <SliderPanel
                        isOpen={true}
                        leftSectionWidth={"100%"}
                        rightSectionWidth={"0%"}
                        leftChildren={<EdiItemCodeMappingMaintenanceFormPanel />}
                    />
                )
            }
        </div>
    </>
}
export default memo(EdiItemCodeMappingMasterView);